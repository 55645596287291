<template>
  <div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 250px)"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column v-for="o in fields" :key="o" :prop="o" :label="o" />
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import _ from 'lodash';
export default {
  props: { node: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      tableSort: { prop: 'error_count', order: 'descending' },

      fields: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    tableSort() {
      this.refresh()
    },
    node() {
      this.init();
      this.refresh()
    }
  },
  created() {
    this.init();
    this.refresh()
  }, 

  methods: {
    async init() {
      this.fields = await this.$api.execute('table.fields', this.node.error_table);
    },
    async refresh() {
      console.log(this.node)

      try {
        this.loading = true;
        const res = await this.$api.execute('table.list', this.node.error_table, _.assign({}, _.pick(this, ['current', 'pageSize', 'tableSort'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },
  }
}
</script>