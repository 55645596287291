<template>
  <div>
    <div style="margin-bottom: 15px;">
      <el-button type="primary" @click="createRecord">添加模型</el-button>
      <el-button :disabled="!selection.length" type="primary" @click="removeSelection">删除选择</el-button>
      <el-button :disabled="!selection.length" type="primary" @click="start(node, false, true)">核验选择</el-button>
      <el-button :disabled="!total" type="primary" @click="start(node, node.last_record_status === 1 ? true : false)">{{node.last_record_status === 1 ? '停止核验' : '核验全部'}}</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 300px)"
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" width="40" />
      <el-table-column prop="data_qa_code" label="模型编号" width="180" />
      <el-table-column prop="data_qa_name" label="模型名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="data_qa_type" label="类型" width="140" />
      <el-table-column prop="data_qa_check_table" label="表名" width="180" />
      <el-table-column prop="data_qa_check_table_cn" label="表名中文" width="180" />
      <el-table-column prop="data_qa_remark" label="规则说明" min-width="180" show-overflow-tooltip />
      <el-table-column prop="options" label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <select-form ref="selectForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import SelectForm from './SelectForm';
export default {
  components: { SelectForm },
  props: { node: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      selection: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    async refresh() {
      if(!this.node) return;
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('data_qa_check.list_item', this.node.id, _.assign({}, _.pick(this, ['current', 'pageSize'])));
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },

    createRecord() {
      this.$refs.selectForm.show({ id: this.node.id }, { title: '添加核验模型', success: () => {
        this.refresh();
      } });
    },
    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将质量模型从列表中移除, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('data_qa_check.remove_item', this.node.id, [obj.data_qa_id])
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e === 'cancel') { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    async removeSelection() {
      try {
        await this.$confirm(`此操作将（${this.selection.length}条）质量模型从列表中移除, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('data_qa_check.remove_item', this.node.id, _.map(this.selection, 'data_qa_id'))
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    async checkSelection() {
      try {
        await this.$api.execute('data_qa_check.execute', this.node.id,  _.map(this.selection, 'data_qa_id'))
        this.refresh();
        this.$message({ type: 'success', message: '操作成功!' });
      } catch(e) {
        this.$message.error(e.message)
      }
    },

    selectionChange(selection) {
      this.selection = selection;
    },

    async start(obj, stop = false, selection = false) {
      try {
        if(stop) {
          await this.$api.execute('task.stop', obj.task_id);
        } else {
          if(selection) {
            await this.$api.execute('task.start', obj.task_id, _.map(this.selection, 'data_qa_id'));
          } else {
            await this.$api.execute('task.start', obj.task_id);
          }
          
        }
        
        this.refresh()
        this.$message.success('操作成功')
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>