<template>
  <div>

    <div style="padding-bottom: 15px; display: flex;">
      <el-button @click="selectCompare">{{compare_id ? `选择比较记录 ${compare_id}` : '选择比较记录'}}</el-button>

      <el-form ref="searchForm" class="form-search" :model="searchForm" inline style="margin-left: 10px;">
        <el-form-item>
          <el-input v-model="searchForm.keyword" placeholder="可按模型编号、模型名称、类型、表名、表名中文搜索" clearable style="width: 400px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="refresh">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 250px)"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column prop="data_qa_code" label="模型编号" width="180" />
      <el-table-column prop="data_qa_name" label="模型名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="data_qa_type" label="类型" width="140" />
      <el-table-column prop="data_qa_check_table" label="表名" width="180" />
      <el-table-column prop="data_qa_check_table_cn" label="表名中文" width="180" />
      
      <el-table-column prop="error_count" label="错误条数" width="100" sortable="custom">
        <template slot-scope="{row}">
          <span>{{row.error_count}}</span>
          <span v-if="compare_id" style="margin-left: 5px;" :style="{color: row.compare_error_count > row.error_count ? '#F56C6C' : '#67C23A'}">{{row.compare_error_count}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_count" label="总数" width="80" sortable="custom">
        <template slot-scope="{row}">
          <span>{{row.total_count}}</span>
          <span v-if="compare_id" style="margin-left: 5px;" :style="{color: row.compare_total_count > row.total_count ? '#F56C6C' : '#67C23A'}">{{row.compare_total_count}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="error_rate" label="错误率" width="80">
        <template slot-scope="{row}">
          {{row.total_count ? `${(row.error_count / row.total_count * 100).toFixed(0)}%` : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="haoshi" label="耗时（秒）" width="100">
        <template slot-scope="{row}">
          {{row.update_time ? `${$moment(row.update_time).diff(row.create_time, 's')}` : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="开始时间" width="180" />
      <el-table-column prop="update_time" label="结束时间" width="180" />

      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button v-if="row.error_table" @click="viewItem(row)" type="text" size="small">查看</el-button>
          <el-button v-if="row.error_table" @click="downloadItem(row)" type="text" size="small">下载</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    <select-form ref="selectForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import * as XLSX from 'xlsx';

import SelectForm from './SelectForm';
export default {
  components: { SelectForm },
  props: { node: Object },
  data() {
    return {
      tableData: [],
      current: 1,
      pageSize: 20,
      total: 0,
      loading: false,

      tableSort: { prop: 'error_count', order: 'descending' },

      compare_id: null,

      searchForm: {}
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    tableSort() {
      this.refresh()
    },
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    async refresh() {
      try {
        this.loading = true;
        const res = await this.$api.execute('data_qa_check_record.list_item', this.node.id, _.assign({}, this.searchForm, _.pick(this, ['current', 'pageSize', 'tableSort', 'compare_id'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },

    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('data_qa_check_record.remove', obj.id)
        this.refresh();
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(_.includes(['close', 'cancel'], e)) { console.log(e) }
        else {
          this.$message.error(e.message)
        }
      }
    },

    viewItem(obj) {
      const label = `结果编号${obj.record_id} ${obj.data_qa_code}`
      this.$store.commit('addTab', { type: 'Pane4', label, node: obj });
      this.$emit('viewItem', label);
    },

    async downloadItem(obj) {
      const [fields, { list: rows }] = await this.$api.executeArray(
        ['table.fields', obj.error_table],
        ['table.list', obj.error_table]
      );

      const wb = XLSX.utils.book_new()

      const ws = XLSX.utils.aoa_to_sheet([
        fields,
        ..._.map(rows, o => _.map(fields, p => o[p]))
      ])

      var range = XLSX.utils.decode_range(ws['!ref'])
      ws['!cols'] = _.map(_.range(range.e.c - range.s.c + 1), c => {
        const wch = _(_.range(range.e.r - range.s.r + 1)).map(r => {
          var cell_ref = XLSX.utils.encode_cell({ c, r })
          return Math.max((ws[cell_ref] ? _(ws[cell_ref].v).map(str => str.match(/[\u4E00-\u9FFF]/) ? 2 : 1).sum() : 0) + 4, 10)
        }).max()
        return { wch }
      })

      XLSX.utils.book_append_sheet(wb, ws, '错误明细')
      XLSX.writeFile(wb, `${obj.data_qa_code}_${obj.data_qa_name}.xlsx`, {})
    },

    selectCompare() {
      this.$refs.selectForm.show({ check_id: this.node.check_id }, { title: '选择比较记录', success: obj => {
        this.compare_id = obj.id;
        this.refresh();
      } });
    },

    print(e) {
      console.log('print', e)
    }
  }
}
</script>