<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="80vw" top="10vh">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
    </span>

    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)" style="margin-top: 10px;"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column prop="id" label="结果编号" width="100" />
      <el-table-column prop="item_count" label="核验模型数" width="100" />
      <el-table-column prop="error_count" label="错误条数" width="100" />
      <el-table-column prop="total_count" label="总条数" width="100" />
      <el-table-column prop="error_rate" label="错误率" width="80">
        <template slot-scope="{row}">
          {{row.total_count ? `${(row.error_count / row.total_count * 100).toFixed(0)}%` : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="haoshi" label="耗时（秒）" width="100">
        <template slot-scope="{row}">
          {{row.update_time ? `${$moment(row.update_time).diff(row.create_time, 's')}` : ''}}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="开始时间" width="180" />
      <el-table-column prop="update_time" label="结束时间" width="180" />
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="confirmForm(row)" type="text" size="small">选择</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableSort: { prop: 'id', order: 'descending' },
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
  },
  methods: {
    async init() {
      this.refresh();
    },

    async refresh() {
      console.log('refresh')
      try {
        this.loading = true;
        const res = await this.$api.execute('data_qa_check_record.list', _.assign({ check_id: this.form.check_id }, _.pick(this, ['current', 'pageSize', 'tableSort'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },

    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.selection = [];
      this.init();
      this.visible = true;
    },

    async confirmForm(obj) {
      this.options.success && this.options.success(obj);
      this.visible = false;
    },
  }
}
</script>