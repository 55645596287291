<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="80vw" top="10vh">
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="!selection.length" type="primary" @click="confirmForm">{{selection.length ? `导入已选（${selection.length}）项` : '请选择导入项'}}</el-button>
    </span>

    <el-table v-loading="loading" :data="tableData" @selection-change="selectionChange" border height="calc(100vh - 400px)" style="margin-top: 10px;"
      :default-sort="tableSort"
      @sort-change="sortChange"
    >
      <el-table-column type="selection" width="39" />
      <el-table-column prop="code" label="规则编号" width="180" />
      <el-table-column prop="name" label="规则名称" min-width="180" show-overflow-tooltip />
      <el-table-column prop="type" label="类型" width="140" />
      <el-table-column prop="remark" label="规则说明" min-width="180" show-overflow-tooltip />
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="current"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      tableSort: { prop: 'code', order: 'descending' },

      selection: [],
    }
  },
  watch: {
    current() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
  },
  methods: {
    async init() {
      this.refresh();
    },

    async refresh() {
      console.log('refresh')
      try {
        this.loading = true;
        const res = await this.$api.execute('data_qa.list', _.assign({}, _.pick(this, ['current', 'pageSize', 'tableSort'])));
        this.tableData = res.list;
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    },

    sortChange({ prop, order }) {
      this.tableSort = order ? { prop, order } : null;
    },

    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.selection = [];
      this.init();
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('data_qa_check.add_item', this.form.id, _.map(this.selection, 'id'));
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log(e)
        this.$message.error(e.message);
      }
    },

    selectionChange(selection) {
      this.selection = selection;
    },
  }
}
</script>